<template>
    <div>
        <input type="checkbox" class="custom-checkbox" v-model="isChecked" @change="$emit('on-change', isChecked)">
    </div>
</template>
<script>
    export default {
        name : 'invision-boolean',
        props: ['initial-value'],
        data () {
            return {
                isChecked : this.$props.initialValue
            }
        }

    }
</script>