<template>
  <BookingWidget
    v-model="show"
    :when-would-you-like-text="whenWouldYouLikeText"
    :choose-your-event-text="chooseYourEventText"
    :other-products-text="otherProductsText"
    :other-products="otherProducts"
    :cf-domain="cfDomain"
    :calendar-bg-colour="calendarBgColour"
    :calendar-text-colour="calendarTextColour"
    :branch-id="branchId"
    :low-capacity-threshold="lowCapacityThreshold"
    :custom-dates="customDates"
    :use-custom-dates="useCustomDates"
    @close="show = false"
  >
  </BookingWidget>
</template>

<script setup>
import {ref} from "vue";
import BookingWidget from "./BookingWidget.vue";

const show = ref(false);

defineProps({
  buyTicketsText: {
    type: String,
    required: true
  },
  whenWouldYouLikeText: {
    type: String,
    required: true,
  },
  chooseYourEventText: {
    type: String,
    required: true,
  },
  otherProductsText: {
    type: String,
    required: true,
  },
  calendarBgColour: {
    type: String,
    required: true,
  },
  calendarTextColour: {
    type: String,
    required: true,
  },
  cfDomain: {
    type: String,
    required: true,
  },
  branchId: {
    type: Number,
    default: null,
    required: false,
  },
  lowCapacityThreshold: {
    type: Number,
    default: null,
    required: false,
  },
  otherProducts: {
    type: Array,
    default: null,
    required: false,
  },
  useCustomDates: {
    type: [Boolean, Number],
    default: false,
    required: false,
  },
  customDates: {
    type: Array,
    default: [],
    required: false,
  },
});
</script>

<style scoped></style>
