<template>
    <div >
        <select class="form-control custom-control" v-model="selectedValue" @change="$emit('on-change', selectedValue)">
            <option v-for="(option) in extraOptions.selectOptions" :value="option.id">{{option.name}}</option>
        </select>
    </div>
</template>
<script>
    export default {
        name : 'invision-select',
        props: ['initial-value', 'extra-options'],
        data () {
            return {
                selectedValue : this.$props.initialValue
            }
        }

    }
</script>