<template>
    <div class="card h-100" v-if="day" v-bind:class="cardClasses.card" @click="selectCard">
        <div class="card-header text-right" v-bind:class="cardClasses.text">
            <span class="d-inline-block date-circle"  :style="cardFooterStyle()">
                <span class="date-circle-number">{{ day }}</span>
            </span>

        </div>
        <div class="card-body px-1 py-1 bg-white">
            <small>Events</small>
            <template v-for="event in store.getters.getDateAttachment('events', dateString)">
                <event-tag :event="event" :mode="'calendar'"></event-tag>
            </template>
            <hr>
            <small>Schedule</small><br>
            <template v-for="schedule, n in store.getters.getDateAttachment('schedules', dateString)">
                <schedule-tag :schedule="schedule" :index="n" :mode="'calendar'"></schedule-tag>
            </template>
        </div>

    </div>
</template>
<script>
    import { store } from '../store'
    import { inDate } from '../../functions'
    import eventTag from './eventTag.vue'
    import scheduleTag from './scheduleTag.vue'
    import _ from 'lodash'

    export default {
        props : ['day', 'month', 'year'],
        components : {
            eventTag,
            scheduleTag
        },
        data() {
            return {
                store
            }

        },
        computed : {
            date () {
                return {
                    day : this.$props.day,
                    month : this.$props.month,
                    year : this.$props.year
                }
            },
            dateString () {
                return inDate.objToString(this.date);
            },
            cardClasses () {
                if (inDate.compare(this.date, store.state.selected)) {
                    return {
                        card: 'card-secondary',
                        text: 'text-alt'
                    }
                }
                else if (inDate.compare(this.date, store.state.today)) {
                    return {
                        card : 'card-info',
                        text : 'text-primary'
                    }
                }
                else {
                    return {
                        card : 'card-med-grey',
                        text : 'text-primary'
                    }
                }
            },
            cardDate() {
                return {
                    day : this.$props.day,
                    month :  this.$props.month,
                    year :  this.$props.year
                }
            },
            linkables () {
                return store.getters.getDateAttachment('linkables', this.dateString)
            },
        },
        methods : {
            selectCard() {

                store.commit('setSelected', this.cardDate)
            },
            cardFooterStyle() {
                let backgroundColor = 'white', color = 'black';

                if (this.linkables !== null && this.linkables.length > 0) {
                    let linkable = _.first(this.linkables);

                    backgroundColor =  store.getters.linkableColour(linkable)
                    color = 'white'
                }

                return {
                    backgroundColor,
                    color
                }

            }

        }
    }
</script>