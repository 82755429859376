<template>
    <section>
            <div>
                <input
                        class="form-control"
                        v-bind:class="{ 'text-danger' : isInvalid }"
                        v-model="currentValue"
                        placeholder="HH:MM"
                        @change="emitChange"
                >
            </div>
    </section>
</template>

<script>
    export default {
        name : 'invision-time-picker',
        props:  {
            initialValue : {
                default: '00:00',
                type: String
            }
        },

        data () {
            return {
                currentValue : this.noSeconds(this.$props.initialValue),
                // Initial value
                config: {
                    format: 'HH:MM'
                },
            }
        },
        computed : {
            isInvalid () {
                let timeValidator = new RegExp("^([0-1][0-9]|2[0-3]):([0-5][0-9])$");
                return !timeValidator.test(this.currentValue);
            },
        },
        methods : {
            emitChange()
            {
                this.$emit('on-change', this.currentValueString());
            },
            currentValueString()
            {
                return this.currentValue + ':00';
            },
            noSeconds(string)
            {
                if (string) {
                    return string.slice(0,5);
                } else {
                    return null;
                }

            }
        }

    }
</script>