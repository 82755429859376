<template>
  <div
      class="session-date border border-secondary rounded-3"
      :class="{
        'border-2': selected,
        'border-1': !selected,
     }"
  >
    <div class="m-2 d-flex flex-column justify-content-center align-items-center">
      <span class="session-weekday fw-bold mb-1">{{ momentDate.format('dddd') }}</span>
      <span
          class="session-day"
          :class="{
            'bg-secondary text-light': selected,
            'bg-primary': !selected,
          }"
      >
        {{ momentDate.format('DD') }}
      </span>
      <span class="session-month">{{ momentDate.format('MMMM') }}</span>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import moment from "moment";

const props = defineProps({
  date: {
    type: Date,
    required: true,
  },
  selected: {
    type: Boolean,
    required: true
  }
});

const momentDate = ref(moment(props.date));
</script>

<style scoped lang="scss">
.session-date {
  min-width: 110px;
  padding: 0 5px;

  // Force a fixed height to ensure box-sizing works. Width is managed by min-width
  //height: 112px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.session-day {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  line-height: 36px;
  text-align: center;
  &.bg-primary { background: #ECECEC }
  &.bg-secondary { background: #000 }
}
</style>

<style lang="scss">
.session-day {
  &.bg-primary { background: #ECECEC !important; }
  &.bg-secondary { background: #000 !important; }
}
</style>