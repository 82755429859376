export const buildUri = (url, params) => {
  const queryParams = new URLSearchParams();

  if (!Object.keys(params).length) {
    return url;
  }

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (Array.isArray(value)) {
      value.forEach((v) => queryParams.append(`${key}[]`, v));
    } else {
      queryParams.append(key, value);
    }
  })

  return `${url}?${queryParams.toString()}`
};
