export const thumbImage = (url, width, height, keepRatio = false) => {
    const parsedUrl = new URL(url);
    const searchParams = new URLSearchParams(parsedUrl.search);

    const path = parsedUrl.pathname.slice().replace(/\.[^/.]+$/, "");
    const extension = parsedUrl.pathname.slice().split(".").splice(-1)[0]

    parsedUrl.pathname = `${path}.${parseInt(width, 10)}x${parseInt(height, 10)}.${extension}`;
    searchParams.delete('v')

    if (!keepRatio) {
        searchParams.set('zc', '1');
    }

    parsedUrl.search = searchParams.toString();

    return parsedUrl.toString();
}