<template>
    <div>
    <span class="badge badge-sm" v-bind:class="eventData.featured?'badge-info':'badge-med-grey'">
        {{ eventData.title }}
    </span>
        <input ref="check" v-if="selected" type="checkbox" :checked="eventData.featured?'checked':''" @change="updateFeatured">
    </div>
</template>
<script>
    import { store } from '../store'

    export default {
        props : ['event', 'mode'],
        computed : {
            eventData() {
                return store.state.events[this.$props.event]
            },
            selected() {
                return (this.$props.mode === 'selected')
            }
        },
        methods : {
            updateFeatured() {
                store.dispatch('updateEventField', {
                    id : this.$props.event,
                    checked : this.$refs.check.checked
                })
            }
        }
    }
</script>