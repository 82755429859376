<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center border rounded-3 p-2 session-time"
    :class="{
        'border-secondary': sessionAvailable,
        'border-disabled': !sessionAvailable,
        'border-1': !selected,
        'border-2': selected,
      }"
    :role="sessionAvailable ? 'button' : undefined"
    @click="sessionAvailable ? emit('sessionChosen', session) : undefined"
  >
    <p
      class="mb-0 fw-bold"
      :class="{
        'text-decoration-line-through': !sessionAvailable,
        'text-disabled': !sessionAvailable
      }"
    >
      {{ moment(sessionStartTime(session)).format('HH:mm') }}
    </p>

    <span
      :class="availabilityData.class"
    >
      <small><i class="fa-sharp fa-solid fa-circle me-2"></i>{{ availabilityData.text }}</small>
    </span>
  </div>
</template>

<script setup>
import {sessionStartTime} from "../functions/sessionFunctions";
import moment from "moment/moment";
import {computed} from "vue";

const props = defineProps({
  session: {
    type: Object,
    required: true
  },
  selected: {
    type: Boolean,
    required: true,
  },
  lowCapacityThreshold: {
    type: Number,
    default: null,
    required: false,
  }
});

const emit = defineEmits(['sessionChosen']);

const sessionAvailable = props.session.spacesAvailable > 0;

const availabilityData = computed(() => {
  if (props.session.spacesAvailable <= 0) {
    return {
      text: 'Sold Out',
      class: 'text-danger',
    };
  }

  if (props.session.spacesAvailable <= props.lowCapacityThreshold) {
    return {
      text: 'Limited',
      class: 'text-warning',
    };
  }

  return {
    text: 'Available',
    class: 'text-success',
  };
});
</script>

<style scoped lang="scss">
.session-time {
  // Force a fixed height to ensure box-sizing works. Width is controlled by the col-4 class
  height: 68px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  i {
    font-size: 12px
  }

  border-radius: 6px;
  border: 1px solid #5E5E5E;
  background: #F8F8F8;

  &.border-secondary {
    border-color: #5E5E5E !important;
  }
}

.text-disabled {
  text-decoration: underline;
  text-underline-offset: -50%;
  text-decoration-skip-ink: none;
}

</style>
