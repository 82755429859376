<template>
    <span v-on:click="clickedSquare" v-on:mouseover="hoveredSquare" class="w-100 h-100 d-inline-block" :style="squareStyle">
    {{ day }}
    </span>
</template>

<script>
    import { store } from '../store'
    import {bus} from '../../bus';
    import { inDate } from '../../functions'


    export default {
        props : ['day', 'month', 'year', 'selectedLinkable'],
        components : {

        },
        data() {
            return {
                store
            }
        },
        computed : {
            date () {
                return {
                    day : this.$props.day,
                    month : this.$props.month,
                    year : this.$props.year
                }
            },
            dateString () {
                return inDate.objToString(this.date);
            },
            toggles (){
                return store.state.toggles;
            },
            linkables () {
                return store.getters.getDateAttachment('linkables', this.dateString)
            },
            squareStyle() {
                let color = '', backgroundColor = '', border = '5px solid transparent';
                if ( this.toggles.selectingDateRange &&
                    (((inDate.objToDate(this.date) >= store.state.yearViewClickedStartDate)
                    && (inDate.objToDate(this.date) <= store.state.yearViewHoverDate))
                    ||
                    ((inDate.objToDate(this.date) >= store.state.yearViewHoverDate)
                    && (inDate.objToDate(this.date) <= store.state.yearViewClickedStartDate)))
                ) {
                    border = '5px solid ' + store.getters.linkableColour(this.$props.selectedLinkable); }
                    if (this.linkables !== null){
                        backgroundColor = store.getters.linkableColour(this.linkables[0]);
                        color = store.getters.linkableTextColour(this.linkables[0]);
                    }


                return {
                    color,
                    backgroundColor,
                    border,
                }
            }

        },
        created () {
            bus.on('updatedHover', this.updatedHover);
        },
        destroyed () {
            bus.off('updatedHover', this.updatedHover);
        },
        methods : {
            clickedSquare() {
                store.dispatch('clickedSquare', {
                  date : this.date,
                  linkable : this.$props.selectedLinkable
                })
            },
            hoveredSquare() {
                store.dispatch('hoveredSquare',  {
                  date : this.date,
                  linkable : this.$props.selectedLinkable
                })
            },
        }
    }
</script>
