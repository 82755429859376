<template>
  <div>
    <div id="controlBarWrap">
      <div class="panel panel-default p-2" id="controlBar" v-show="toggles.init">
        <div class="row">
          <div class="col-auto px-3">
            <button class="btn btn-outline-secondary" v-on:click="addTable()"><i class="fa fa-plus-circle me-2"
                                                                                 aria-hidden="true"></i>Add Table
            </button>
          </div>

          <div class="col">

          </div>
          <div class="col-auto px-3">
            <button class="btn btn-success" v-on:click="saveTableData()">
                    <span v-if="toggles.saving">
                        <i class="fa fa-spinner fa-spin"></i>
                    </span>
              <span v-else>
                        Save Data
                    </span>
            </button>
          </div>

        </div>
      </div>
    </div>
    <div v-if="!toggles.init">
      <p v-if="!toggles.error"><i class="fa fa-spin fa-spinner fa-2x"></i> Loading data ...</p>
      <p v-else>An error occurred while attempting to load your data, try refreshing the page </p>
    </div>
    <div class="tables" v-else>
      <div v-for="(table, tableIndex) in tables">
        <div class="panel panel-default">
          <div>
            <div class="panel panel-default p-2" v-show="toggles.init">
              <div class="row">
                <div class="col-auto px-3">
                  <button class="btn btn-outline-secondary" v-on:click="addRow(tableIndex)">
                    <i class="fa fa-plus-circle me-2" aria-hidden="true"></i> Add Row
                  </button>
                </div>

                <div class="col-auto px-3">
                  <button class="btn btn-outline-secondary" v-on:click="addColumn(tableIndex)">
                    <i class="fa fa-plus-circle me-2" aria-hidden="true"></i> Add Column
                  </button>
                </div>
                <div class="col">

                </div>
                <div class="col-auto px-3">
                  <button class="btn btn-outline btn-outline-danger" v-on:click="removeTable(tableIndex)">
                    <i class="fa fa-trash-o me-2" aria-hidden="true"></i> Remove Table
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--          <p class="muted px-2"><i class="fa fa-info-circle text-info"></i> Click on cells/titles to edit</p>-->
          <div class="table-responsive">
            <table class="table" style="overflow-x: auto">
              <thead>
              <tr>
                <template v-for="(field, n) in table.fields">
                  <th class="d-block" v-if="extractName(field.name) === '__slot'">
                    {{ field.title }}
                  </th>
                  <th v-else>
                    <template v-if="n > 0">
                      <button v-if="n > 1"
                              class="btn btn-outline-primary btn-xs mb-2"
                              @click="columnLeft(tableIndex, n)">
                        <i class="fa fa-angle-left"></i>
                      </button>
                      <button v-if="n < table.fields.length - 2"
                              class="btn btn-outline-primary btn-xs mb-2"
                              @click="columnRight(tableIndex, n)">
                        <i class="fa fa-angle-right"></i>
                      </button>
                      <button class="btn btn-outline-danger btn-xs mb-2"
                              v-on:click="removeColumn(tableIndex, n)">
                        <i class="fa fa-times"></i>
                      </button>
                    </template>
                    <input-field v-on:input="changeValue"
                                 :table-index="tableIndex"
                                 v-bind:value="field.title"
                                 v-bind:column="n"
                                 v-bind:row="-1">
                    </input-field>

                  </th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, m) in table.data"
                  v-bind:class="[rowIsChecked(tableIndex, m) ? 'table-success' : '' ]">
                <template v-for="(field, o) in table.fields">
                  <td
                      v-if="extractName(field.name) === '__slot'"
                      :key="`${o}-slot`"
                      :class="['vuetable-slot', field.dataClass]"
                  >
                    <div class="custom-actions">
                      <button class="btn btn-outline-danger btn-xs" v-on:click="removeRow(tableIndex, m)">
                        <i class="fa fa-times"></i>
                      </button>
                      <button v-if="m > 0"
                              class="btn btn-outline-primary btn-xs"
                              @click="rowUp(tableIndex, m)"
                      >
                        <i class="fa fa-angle-up"></i>
                      </button>
                      <button v-if="m < table.data.length-1"
                              class="btn btn-outline-primary btn-xs"
                              @click="rowDown(tableIndex, m)"
                      >
                        <i class="fa fa-angle-down"></i>
                      </button>
                      <input type="checkbox" class="d-none" v-model="checkedRows[tableIndex]" :value="m" @change="updateFeaturedRows(tableIndex)">
                    </div>
                  </td>
                  <td v-else :key="o">
                    <input-field
                        v-on:input="changeValue"
                        :table-index="tableIndex"
                        v-bind:value="row[field.name]"
                        v-bind:row="m"
                        v-bind:column="o"
                    />
                  </td>
                </template>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="panel panel-default my-2 p-2">
          <div class="row">
            <div class="col-12">
              <h6>Table Preview</h6>
              <div class="table-responsive">
                <table v-show="table.rawData.length > 0" class="table-striped table listing-table"
                       id="listing-table">
                  <thead v-bind:style="tableStyle.head">
                  <tr>
                    <th v-for="title in table.rawData[0]">
                      {{ title }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, i) in table.rawData.length">
                    <td v-for="value in table.rawData[i]" v-if="i !== 0">
                      {{ value }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from './inputfield.vue'

import {store} from '../store'
import axios from '../../request'
import swal from "sweetalert2";


export default {

  props: ['api'],
  data() {
    return {
      checkedRows: [[]]
    }
  },
  mounted() {
    axios.get(this.$props.api + '/loadJson')
        .then((response) => {
          store.commit('loadTableData', response.data);
          this.$data.checkedRows = this.featuredRows;
        })
        .catch(function (error) {
          store.commit('loadingError', error);
          console.log(error)
        });
  },
  components: {
    InputField
  },
  computed: {
    featuredRows() {
      return store.state.json.map(table => table.featuredRows);
    },
    tables() {
      return store.state.json;
    },
    toggles() {
      return store.state.toggles
    },
    tableStyle() {
      return {
        head: {
          backgroundColor: store.state.tableConfig.backgroundColour,
          color: store.state.tableConfig.textColour,
        }
      }
    }

  },
  methods: {
    changeValue() {
      this.$forceUpdate();
    },
    extractName(string) {
      return string.split(':')[0].trim()
    },
    extractArgs(string) {
      return string.split(':')[1]
    },
    addColumn(tableIndex) {
      store.commit('addColumn', {tableIndex});
      this.$forceUpdate();
    },
    removeColumn(tableIndex, columnIndex) {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!'
      }).then(() => {
        swal(
            'Removed!',
            'Column removed.',
            'success'
        )
        store.commit('removeColumn', {tableIndex, columnIndex});
        this.$forceUpdate();
      }, (dismiss) => {
        if (dismiss === 'cancel') {
          console.info('Cancelled Removal')
        }
      });

    },
    addRow(tableIndex) {
      store.commit('addRow', {tableIndex})
      this.$forceUpdate();
    },
    removeRow(tableIndex, rowIndex) {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!'
      }).then(() => {
        swal(
            'Removed!',
            'Row removed.',
            'success'
        );
        store.commit('removeRow', {tableIndex, rowIndex})
        this.$forceUpdate();
      }, (dismiss) => {
        if (dismiss === 'cancel') {
          console.info('Cancelled Removal')
        }
      });

    },
    addTable() {
      store.commit('addTable')
      this.checkedRows.push([]);
    },
    removeTable(tableIndex) {
      store.commit('removeTable', {tableIndex});
      this.checkedRows.splice(tableIndex, 1);
    },
    rowUp(tableIndex, rowIndex) {
      store.commit('rowUp', {tableIndex, rowIndex})
      this.$forceUpdate();
    },
    rowDown(tableIndex, rowIndex) {
      store.commit('rowDown', {tableIndex, rowIndex})
      this.$forceUpdate();
    },
    columnLeft(tableIndex, columnIndex) {
      store.commit('columnLeft', {tableIndex, columnIndex})
      this.$forceUpdate();
    },
    columnRight(tableIndex, columnIndex) {
      store.commit('columnRight', {tableIndex, columnIndex})
      this.$forceUpdate();
    },
    saveTableData() {
      let url = this.$props.api + '/updateJson';
      store.commit('saveTableData', url)
    },
    goBack() {
      swal({
        title: 'Are you sure?',
        text: "You will lose any unsaved work",
        type: 'warning',
        showCancelButton: true,
      })
    },
    rowIsChecked(tableIndex, row) {
      return (this.checkedRows[tableIndex].indexOf(row) > -1);
    },
    updateFeaturedRows(tableIndex) {
      store.commit('updateFeaturedRows', {tableIndex, rows: this.$data.checkedRows[tableIndex]});
    },
  }
}
</script>
