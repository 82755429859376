<template>
    <section>
        <div class="form-group">
            <div class="input-group">
                <flat-pickr
                        :value="initialValue"
                        :config="config"
                        class="form-control"
                        placeholder="Select date"
                        name="date"
                        @on-change="emitChange">
                </flat-pickr>
                <div class="input-group-btn flex-row">
                    <span class="btn btn-default btn-sm" type="button" title="Toggle" data-toggle>
                        <i class="fa fa-calendar">
                            <span aria-hidden="true" class="sr-only">Toggle</span>
                        </i>
                    </span>
                    <span class="btn btn-default btn-sm" type="button" title="Clear" data-clear>
                        <i class="fa fa-times">
                            <span aria-hidden="true" class="sr-only">Clear</span>
                        </i>
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    // import this component
    import flatPickr from 'vue-flatpickr-component';

    export default {
        props : {
            initialValue : {
                default: () => new Date().toDateString('Y-m-d'),
                type: String
            },
            extraOptions : {
                default : () => {
                    return {
                        config : {
                            wrap: true, // set wrap to true only when using 'input-group'
                            altFormat: 'd F Y H:i',
                            altInput: true,
                            dateFormat: 'Y-m-d H:i',
                            enableTime: true
                        }
                    }
                }
            }
        },
        name : 'invision-date-picker',

        data () {
            return {
                // Initial value
                config: this.$props.extraOptions.config,
            }
        },
        components: {
            flatPickr
        },
        methods: {
            emitChange(date,dateString,flatpickrObj,extra) {
                this.$nextTick( () => {
                    this.$emit('on-change', dateString);
                })

            }
        }
    }
</script>