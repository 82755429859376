import {createStore} from 'vuex';
import axios from '../request';

import _ from 'lodash'
import { InvisionFieldDefaultSettings } from '../common/invisionFieldFactory'

export const store = createStore({
    state: {
        toggles: {
            init: false,
            error: false,
            saving: false,
        },
        entries : [],
        lists : [],
        settings : {
            related :[]
        },
        with : '',
        api: ''
    },
    mutations: {
        importSettings(state, settings){
            state.settings = settings;
        },
        clearRelatedData(state){
            state.entries.splice(0,1)
        },
        loadRelatedData(state, data){
            _.each(data, function(entry){
                state.entries.push(entry)
            });
            state.toggles.saving = false;

        },
        initialised (state){
            state.toggles.init = true;
        },
        toggleSaving(state)
        {
            state.toggles.saving = true;
        },
        addRow (state, data) {
            if (typeof state.entries[0][data.type] === 'undefined') {
                state.entries[0][data.type] = [];
            }
            state.entries[0][data.type].push(data.template)
        },
        deleteRow (state, data) {
            state.entries[0][data.type].splice(data.index, 1)
        },
        updateRow (state, data) {
            state.entries[0][data.type][data.index] = data.values ;
        },
        setApi (state, data)
        {
            state.api = data;
        },
        setWith (state, data)
        {
            state.with = data;
        }

    },
    actions: {
        loadListings({commit, state}, data){

            commit('loadRelatedData', data);
            commit('initialised')
        },
        addRow({commit, state}, data) {

            if (typeof state.settings.related[data.type] !== 'undefined') {

                let template = {};
                _.each(state.settings.related[data.type], (item) => {
                    if (item.field === 'id') {
                        template[item.field] = '_' + Math.random().toString(36).substr(2, 5);
                    } else {
                        template[item.field] = InvisionFieldDefaultSettings.getDefaultFor(item.type);
                    }
                })
                ;
                commit('addRow', {type : data.type, template})
            } else {
                console.warn(data.type + ' is not defined in settings.')
            }
        },
        deleteRow({commit, state}, data) {
            if (typeof state.settings.related[data.type] !== 'undefined') {
                commit('deleteRow', data)
            } else {
                console.warn(data.type + ' is not defined in settings.')
            }
        },
        updateRow({ commit, state}, data) {
            console.log (data);
            let index = _.findIndex(state.entries[0][data.type], (o) => {return o.id === data.values.id});
            if (index !== -1) {
                data.index = index;
                commit('updateRow', data)
            }

        },
        saveData({ commit, state}) {
            commit('toggleSaving');
            axios.post(state.api + '/save',
                {
                    with : [state.with],
                    entry: state.entries[0]
                })
                .then ( (response) => {
                    console.log(response);

                    commit('clearRelatedData');
                    commit('loadRelatedData', response.data)
                })
                .catch ( (error) => {
                    console.log(error);
                })
        }
    }
});