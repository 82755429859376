<template>
    <div class="panel panel-default">
        <h5 class="bg-secondary text-alt p-1 my-0">{{ friendlySelected }}</h5>
        <div class="p-1">
            <div>
                <h6 class="text-primary">Events On Today</h6>
                <template v-for="event in store.getters.getDateAttachment('events', selected)">
                    <event-tag :event="event" :mode="'selected'"></event-tag>
                </template>
            </div>
            <h6 class="text-primary">Today's Schedule</h6>
            <template v-for="schedule, n in orderScheduleByTime()">
                <schedule-tag :schedule="schedule" :index="n" :mode="'selected'"></schedule-tag>
            </template>
            <h6 class="text-primary">Today's Calendar Item </h6>
            <div v-for="linkable in store.getters.getDateAttachment('linkables', selected)">
                {{ store.state.linkables[linkable].linked_item_object.title ||  store.state.linkables[linkable].linked_item_object.name }}

            </div>
        </div>
    </div>
</template>
<script>
    import { store } from '../store';
    import eventTag from './eventTag.vue'
    import scheduleTag from './scheduleTag.vue'
    import { inDate } from '../../functions'
    import _ from 'lodash'

    export default {
        data() {
            return {
                store
            }
        },
        props : ['selected'],
        components : {
            eventTag,
            scheduleTag
        },
        computed : {
          friendlySelected() {
              return inDate.strToFriendly(this.$props.selected, 'en')
          }
        },
        methods : {
            orderScheduleByTime(){
                let schedule = store.getters.getDateAttachment('schedules', this.selected);
                if (schedule) {
                    let orderedSchedule = [];
                    for (let i = 0, len = schedule.length; i < len; i++) {
                        if (store.state.schedules[schedule[i]]) {
                            let obj = store.state.schedules[schedule[i]];
                            if (!obj.allday) {
                                obj.timeInSec = inDate.timeToSeconds(obj.time)
                            } else {
                                obj.timeInSec = 0;
                            }
                            orderedSchedule.push(obj)
                        }
                    }
                    orderedSchedule = _.sortBy(orderedSchedule, [function (o) {
                        return o.timeInSec;
                    }]);
                    for (let i = 0, len = orderedSchedule.length; i < len; i++) {
                        orderedSchedule[i] = orderedSchedule[i].id;
                    }
                    return orderedSchedule;
                } else {
                    return null
                }
            }
        }
    }
</script>